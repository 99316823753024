import "./App.css";
import cardJson from "./card_data.json";
import { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import { Card as MUICard } from "@mui/material";
import { TextField } from "@mui/material";
import { CardHeader, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import darkmode from "./dark-mode.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ReactAudioPlayer from "react-audio-player";
import ConfettiExplosion from "react-confetti-explosion";

const GUESSES_ALLOWED = 2;
const DEFAULT_CONFETTI_CONFIG = {
  force: 0.2,
  duration: 2200,
  particleCount: 30,
  width: 300,
};
const DEFAULT_AUDIO_SPEED = 1;

function Flashcard({
  currentCard,
  respondIn,
  questionsIn,
  userResponse,
  setUserResponse,
  currentCardIndex,
  setCurrentCardIndex,
  results,
  setResults,
  showCharacter,
  setShowCharacter,
  showAudioTooltip,
  setShowAudioTooltip,
  bookmarks,
  setBookmarks,
  handleClickBookmark,
  cardIsStoredAsBookmark,
  getIndexOfExistingBookmark,
  answers,
  setAnswers,
}) {
  const [answeredWrong, setAnsweredWrong] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [guessesLeft, setGuessesLeft] = useState(GUESSES_ALLOWED);
  const [isExploding, setIsExploding] = useState(false);
  const [confettiConfig, setConfettiConfig] = useState(DEFAULT_CONFETTI_CONFIG);
  const [audioSpeed, setAudioSpeed] = useState(DEFAULT_AUDIO_SPEED);

  function focusResponse() {
    document.getElementById("user-response").focus();
  }

  useEffect(() => {
    focusResponse();
    if (showAudioTooltip && currentCardIndex > 0) {
      localStorage.setItem("show_audio_tooltip", "false");
      setShowAudioTooltip(false);
    }
  });

  function determinePrompt() {
    if (questionsIn === "audio_mandarin") {
      return "";
    } else if (questionsIn === "pinyin") {
      return (
        <Tooltip
          title={
            <Typography color="inherit">
              tap/click mandarin for audio
            </Typography>
          }
          placement="left"
          arrow
          open={showAudioTooltip && currentCardIndex === 0 ? true : false}
        >
          <Box
            onMouseDown={playAudio}
            onClick={focusResponse}
            display="inline"
            className="cursor-on-hover"
          >
            {currentCard.pinyin}
          </Box>
        </Tooltip>
      );
    } else if (questionsIn === "simplified_character") {
      return (
        <Tooltip
          title={
            <Typography color="inherit">
              tap/click mandarin for audio
            </Typography>
          }
          placement="left"
          arrow
          open={showAudioTooltip && currentCardIndex === 0 ? true : false}
        >
          <Box
            onMouseDown={playAudio}
            onClick={focusResponse}
            display="inline"
            className="cursor-on-hover"
          >
            {currentCard.simplified_character}
          </Box>
        </Tooltip>
      );
    } else if (questionsIn === "english") {
      return currentCard.english
        .split(",")
        .map((answer) => answer.trim().toLowerCase())
        .join(", ");
    }
  }

  function playAudio() {
    const file =
      audioSpeed === 1
        ? currentCard.audio_mandarin
        : currentCard.audio_mandarin_half_speed;
    const audio = new Audio(file);
    audio.play();
    setAudioSpeed(audioSpeed === 1 ? 0.5 : 1);
  }

  function getCorrectAnswer() {
    return currentCard[respondIn];
  }

  function displayCharacter() {
    return ["pinyin", "audio_mandarin"].includes(questionsIn);
  }

  function isStreak(currentStreak) {
    return [5, 10, 20, 30, 50].includes(currentStreak);
  }

  //onHitEnter
  function handleSubmit(event) {
    event.preventDefault();
    setIsExploding(false);
    // remove whitespace and turn into array
    console.log(`getCorrectAnswer: ${getCorrectAnswer()}`);
    let correctAnswer = getCorrectAnswer()
      .split(",")
      .map((answer) => answer.trim().toLowerCase());
    console.log(currentCard);
    console.log(`correct answer ${correctAnswer}`);
    setCorrectAnswer(correctAnswer);
    if (
      correctAnswer.includes(userResponse.toLowerCase().trim()) ||
      userResponse.toLowerCase().trim() === correctAnswer.join(", ")
    ) {
      console.log("answered right");
      setAnsweredWrong(false);

      // if they got the answer without assistance,
      // consider it correct
      if (guessesLeft >= 1) {
        let newResults = { ...results };
        newResults.correct += 1;
        newResults.currentStreak += 1;
        if (newResults.currentStreak >= newResults.longestStreak) {
          newResults.longestStreak = newResults.currentStreak;
        }

        if (isStreak(newResults.currentStreak)) {
          let newConfettiConfig = { ...confettiConfig };
          newConfettiConfig.force += 0.3;
          newConfettiConfig.duration += 100;
          newConfettiConfig.particleCount += 100;
          newConfettiConfig.width += 500;
          setConfettiConfig(newConfettiConfig);
          setIsExploding(true);
        }

        // record card as answered correctly
        let newAnswers = [...answers];
        console.log(newAnswers);
        newAnswers.push(
          JSON.stringify({
            flashcard_id: currentCard.id,
            correct: true,
            questionsIn: questionsIn,
            respondIn: respondIn,
          })
        );
        setAnswers(newAnswers);
        setResults(newResults);
      }
      setUserResponse("");
      const newIndex = currentCardIndex + 1;
      setAudioSpeed(DEFAULT_AUDIO_SPEED);
      localStorage.setItem("current_card_index", newIndex);
      setCurrentCardIndex(newIndex);
      setGuessesLeft(GUESSES_ALLOWED);
    } else {
      // one fewer guess remaining
      let newGuessesLeft = guessesLeft;
      newGuessesLeft -= 1;
      setGuessesLeft(newGuessesLeft);
      if (newGuessesLeft === 0) {
        setConfettiConfig(DEFAULT_CONFETTI_CONFIG);
        // record card as answered incorrectly in the backend
        // if the user fails to get their 'backup guess' correct
        let newAnswers = answers;
        newAnswers.push(
          JSON.stringify({
            flashcard_id: currentCard.id,
            correct: false,
            questionsIn: questionsIn,
            respondIn: respondIn,
          })
        );
        setAnswers(newAnswers);

        // add card to list of incorrect cards for the round
        let newResults = { ...results };
        newResults.incorrect += 1;
        newResults.incorrectCards.push(currentCard);
        if (guessesLeft <= 1) {
          console.log("resetting current streak");
          newResults.currentStreak = 0;
        }
        setResults(newResults);
      }

      setUserResponse("");

      // mark card as having been answered wrong
      setAnsweredWrong(true);
    }
  }

  // TODO stretch goal:
  // add google text-to-speech
  // https://github.com/GoogleCloudPlatform/cloud-shell-tutorials/blob/master/ml/cloud-tts-intro/tutorial.md
  // seems like i'd convert each piece of text into an mp3 file and store the file somewhere (S3?)
  // and then serve up the file appropriately.
  // Assuming flashcards are saved in a DB, upon creating a flashcard, the associated mp3 file could be
  // created and stored in association with the flashcard's text data.
  // The reason for using google text to speech is that mandarin is supported
  // although -- this dude uses whisper? https://news.ycombinator.com/item?id=36973400
  //
  return (
    <Container>
      <Container>
        <MUICard variant="" align="center">
          <CardContent>
            {questionsIn === "audio_mandarin" && currentCard.audio_mandarin && (
              <>
                {" "}
                <ReactAudioPlayer
                  src={currentCard.audio_mandarin}
                  autoPlay
                  controls
                  onPlay={focusResponse}
                  controlsList={"nodownload"}
                />
                <br />
              </>
            )}
            <Button onClick={() => handleClickBookmark(currentCard)}>
              {cardIsStoredAsBookmark(currentCard) ? (
                <FavoriteOutlinedIcon />
              ) : (
                <FavoriteBorderOutlinedIcon />
              )}
            </Button>
            <CardHeader
              className="card-header"
              title={determinePrompt()}
              subheader={
                displayCharacter() ? currentCard.simplified_character : ""
              }
              titleTypographyProps={{ align: "center", variant: "h3" }}
              subheaderTypographyProps={{ align: "center" }}
            />
            {currentCardIndex === 0 && (
              <>
                <Typography variant="h7" color="#95a5a6" align="center">
                  responding in: {respondIn}
                </Typography>
                <br />
                <br />
              </>
            )}
            {answeredWrong && guessesLeft <= 0 && (
              <Typography variant="h5" align="center">
                answer:
                <span className="green">{" " + correctAnswer.join(", ")}</span>
              </Typography>
            )}
            {answeredWrong && guessesLeft >= 1 && (
              <Typography variant="h5" align="center">
                not quite, try again
              </Typography>
            )}
          </CardContent>
        </MUICard>
      </Container>

      <MUICard variant="" align="center">
        <CardContent>
          <Typography variant="h3">
            <form onSubmit={handleSubmit}>
              <TextField
                align="center"
                id="user-response"
                style={{ textAlignVertical: "top" }}
                type="text"
                variant="standard"
                value={userResponse}
                onChange={(event) => setUserResponse(event.target.value)}
              />
            </form>
          </Typography>

          <Typography variant="h7" color="#95a5a6">
            streak: {results.currentStreak}
          </Typography>

          {isExploding && <ConfettiExplosion {...confettiConfig} />}
        </CardContent>
      </MUICard>
    </Container>
  );
}

function Favorites({ bookmarks, handleClickBookmark, cardIsStoredAsBookmark }) {
  return (
    <>
      <Typography variant="h5" color="#95a5a6" subheader="">
        Review Saved Cards
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>English</TableCell>
              <TableCell>Simplified</TableCell>
              <TableCell>Pīnyīn</TableCell>
              <TableCell>Audio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookmarks.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <>
                    <Button onClick={() => handleClickBookmark(row)}>
                      {cardIsStoredAsBookmark(row) ? (
                        <FavoriteOutlinedIcon />
                      ) : (
                        <FavoriteBorderOutlinedIcon />
                      )}
                    </Button>
                    {row.english}
                  </>
                </TableCell>
                <TableCell>{row.simplified_character}</TableCell>
                <TableCell>{row.pinyin}</TableCell>
                <TableCell>
                  <ReactAudioPlayer
                    src={row.audio_mandarin}
                    controls
                    controlsList={"nodownload"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function App() {
  const EMPTY_RESULTS = {
    correct: 0,
    incorrect: 0,
    incorrectCards: [],
    currentStreak: 0,
  };
  const DEFAULT_FLASHCARD_QUANTITY = 10;
  const [theme, setTheme] = useState(getThemeInStorage() || "light");

  const [userResponse, setUserResponse] = useState("");
  const [totalFlashcards, setTotalFlashcards] = useState(
    JSON.parse(localStorage.getItem("total_flashcards")) || []
  );
  const [flashcards, setFlashcards] = useState(
    JSON.parse(localStorage.getItem("flashcards")) || []
  );
  const [questionsIn, setQuestionsIn] = useState(
    localStorage.getItem("questions_in") || "audio_mandarin"
  );
  const [respondIn, setRespondIn] = useState(
    localStorage.getItem("respond_in") ||
      (questionsIn === "english" ? "pinyin" : "english")
  );
  const [currentCardIndex, setCurrentCardIndex] = useState(
    parseInt(localStorage.getItem("current_card_index")) || 0
  );
  const [results, setResults] = useState(EMPTY_RESULTS);
  const [roundStarted, setRoundStarted] = useState(
    JSON.parse(localStorage.getItem("round_started")) || false
  );
  const [showCharacter, setShowCharacter] = useState(false);
  const [flashcardQuantity, setFlashcardQuantity] = useState(
    localStorage.getItem("flashcard_quantity") || DEFAULT_FLASHCARD_QUANTITY
  );
  const [level, setLevel] = useState(
    localStorage.getItem("difficulty_level") || "HSK 1"
  );
  const [showAudioTooltip, setShowAudioTooltip] = useState(
    JSON.parse(localStorage.getItem("show_audio_tooltip")) || true
  );
  const [bookmarks, setBookmarks] = useState(
    JSON.parse(localStorage.getItem("bookmarks")) || []
  );
  const [answers, setAnswers] = useState(
    JSON.parse(localStorage.getItem("answers")) || []
  );
  // const [markedBookmarked, setMarkedBookmarked] = useState(
  //   cardIsStoredAsBookmark(currentCard) || false
  // );

  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });

  useEffect(() => {
    ///need to make this a better "CLS" experience --
    // it causes error message to pop up on screen while fetch below occurs.
    console.log("hitting use effect");
    console.log(`level ${level}`);
    if (!flashcards || flashcards.length < 1) {
      console.log("no flashcards");
      localStorage.setItem("total_flashcards", JSON.stringify([]));
      setTotalFlashcards([]);

      let cards = prepareDeck();
      console.log(cards[0].difficuly_level);
      cards = shuffleDeck([...cards]);
      localStorage.setItem("total_flashcards", JSON.stringify(cards));
      setTotalFlashcards(cards);
      const quantity =
        cards.length < DEFAULT_FLASHCARD_QUANTITY
          ? cards.length
          : DEFAULT_FLASHCARD_QUANTITY;
      localStorage.setItem("flashcard_quantity", quantity);
      setFlashcardQuantity(quantity);
      let deck = cards.slice(0, quantity);
      localStorage.setItem("flashcards", JSON.stringify(deck));
      setFlashcards(deck);
    }
  }, [level]);

  function prepareDeck(quantity = null) {
    let deckByLevel = cardJson.filter(
      (card) => card.difficulty_level === level
    );
    let shuffled = shuffleDeck(deckByLevel);
    return quantity ? shuffled.slice(0, flashcardQuantity) : shuffled;
  }

  function getThemeInStorage() {
    return localStorage.getItem("theme");
  }

  function handleClickBookmark(card) {
    // setMarkedBookmarked(!markedBookmarked);
    // record card as answered correctly in the backend
    let newBookmarks = bookmarks.slice();
    const indexOfExistingBookmark = getIndexOfExistingBookmark(card);
    if (indexOfExistingBookmark < 0) {
      // add the bookmark
      console.log("adding bookmark");
      newBookmarks.push(card);
    } else {
      // remove the bookmark
      console.log("removing bookmark");
      newBookmarks.splice(indexOfExistingBookmark, 1);
    }

    localStorage.setItem("bookmarks", JSON.stringify(newBookmarks));
    setBookmarks(newBookmarks);
  }

  function cardIsStoredAsBookmark(card) {
    return getIndexOfExistingBookmark(card) >= 0;
  }

  function getIndexOfExistingBookmark(card) {
    const indexMatches = (element) =>
      element.simplified_character === card.simplified_character;
    const indexOfExistingBookmark = bookmarks.findIndex(indexMatches);
    return indexOfExistingBookmark;
  }

  // should be called only on initial setup
  function shuffleDeck(array) {
    console.log("shuffling...");
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      // pick a remaining element
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function determineCurrentCard() {
    if (currentCardIndex < flashcards.length) {
      return flashcards[currentCardIndex];
    } else {
      //todo: prompt to either
      // a) review wrong answers [will require me storing which responses were incorrect]
      //     -- would also be good to have this data stored over time in a db!
      // b) shuffle and redo the deck
      return null;
    }
  }

  function handleChoosePromptMode(event) {
    const questionMode = event.target.value;
    event.preventDefault();
    if (respondIn === questionMode) {
      localStorage.setItem("respond_in", "");
      setRespondIn("");
    }
    localStorage.setItem("questions_in", questionMode);
    setQuestionsIn(questionMode);
  }

  function handleChooseResponseMode(event) {
    const responseMode = event.target.value;
    if (questionsIn === responseMode) {
      localStorage.setItem("questions_in", "");
      setQuestionsIn("");
    }
    localStorage.setItem("respond_in", responseMode);
    setRespondIn(responseMode);
  }

  function handleChooseFlashcardQuantity(event) {
    const quantity = event.target.value;
    const cards = totalFlashcards.slice(0, quantity);
    localStorage.setItem("flashcards", JSON.stringify(cards));
    setFlashcards(cards);
    localStorage.setItem("flashcard_quantity", quantity);
    setFlashcardQuantity(quantity);
  }

  function handleChooseLevel(event) {
    const difficulty = event.target.value;
    localStorage.setItem("difficulty_level", difficulty);
    setLevel(difficulty);
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <Container>
          {!roundStarted && (
            <Container maxWidth={"sm"}>
              <Box
                component="img"
                sx={{
                  maxHeight: { xs: 30, md: 40 },
                  maxWidth: { xs: 30, md: 40 },
                }}
                alt="dark / light mode"
                src={darkmode}
                className="darkmode"
                onClick={() => {
                  const newTheme = theme === "dark" ? "light" : "dark";
                  localStorage.setItem("theme", newTheme);
                  setTheme(newTheme);
                }}
              />

              <MUICard align="center" variant="">
                <CardHeader
                  className="card-header"
                  title="manda :) grin"
                  subheader="modular chinese language learning"
                  titleTypographyProps={{ align: "center", variant: "h5" }}
                  subheaderTypographyProps={{ align: "center" }}
                />
              </MUICard>
              <Box className="select-level" align="center"></Box>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                justifyContent="flex-end"
                style={{ minHeight: "55vh", alignItems: "stretch" }}
              >
                <Grid item xs={10}>
                  <MUICard align="center" variant="">
                    <CardHeader
                      className="card-header"
                      title={flashcardQuantity}
                      subheader="cards"
                      titleTypographyProps={{ align: "center", variant: "h3" }}
                      subheaderTypographyProps={{ align: "center" }}
                    />
                  </MUICard>
                  <MUICard align="center" variant="">
                    <CardContent>
                      <div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            difficulty level
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={level}
                            label="difficulty level"
                            onChange={handleChooseLevel}
                          >
                            <MenuItem value={"HSK 1"}>HSK 1</MenuItem>
                            <MenuItem value={"HSK 2"}>HSK 2</MenuItem>
                            <MenuItem value={"HSK 3"}>HSK 3</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            questions in
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={questionsIn}
                            label="questions in"
                            onChange={handleChoosePromptMode}
                          >
                            <MenuItem value={"pinyin"}>pīnyīn</MenuItem>
                            <MenuItem value={"simplified_character"}>
                              simplified chinese characters
                            </MenuItem>
                            <MenuItem value={"audio_mandarin"}>
                              mandarin audio - 🔊
                            </MenuItem>
                            <MenuItem value={"english"}>english</MenuItem>
                          </Select>
                          <FormHelperText>
                            questions will be presented in this format
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            respond in
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={respondIn}
                            label="prompt mode"
                            onChange={handleChooseResponseMode}
                          >
                            <MenuItem
                              value={"english"}
                              style={
                                questionsIn === "english"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              english
                            </MenuItem>
                            <MenuItem
                              value={"pinyin"}
                              style={
                                questionsIn === "pinyin"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              pīnyīn
                            </MenuItem>
                            <MenuItem
                              value={"simplified_character"}
                              style={
                                questionsIn === "simplified_character"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              simplified chinese characters
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            you will respond in this format
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            quantity
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={flashcardQuantity}
                            label="quantity"
                            onChange={handleChooseFlashcardQuantity}
                          >
                            {totalFlashcards.length >= 5 && (
                              <MenuItem value={"5"}>5</MenuItem>
                            )}
                            {totalFlashcards.length >=
                              DEFAULT_FLASHCARD_QUANTITY && (
                              <MenuItem value={DEFAULT_FLASHCARD_QUANTITY}>
                                {DEFAULT_FLASHCARD_QUANTITY}
                              </MenuItem>
                            )}
                            {totalFlashcards.length >= 20 && (
                              <MenuItem value={"20"}>20</MenuItem>
                            )}
                            {totalFlashcards.length >= 30 && (
                              <MenuItem value={"30"}>30</MenuItem>
                            )}
                            {totalFlashcards.length >= 40 && (
                              <MenuItem value={"40"}>40</MenuItem>
                            )}
                            {totalFlashcards.length >= 50 && (
                              <MenuItem value={"50"}>50</MenuItem>
                            )}
                            {totalFlashcards.length >= 0 && (
                              <MenuItem value={totalFlashcards.length}>
                                All Cards ({totalFlashcards.length})
                              </MenuItem>
                            )}
                          </Select>
                          <FormHelperText>
                            answering this many flashcards
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </CardContent>
                  </MUICard>

                  <MUICard align="center" variant="">
                    <CardContent>
                      <Tooltip
                        title={
                          respondIn && questionsIn && flashcards.length > 0
                            ? ""
                            : "failed to fetch flashcards, try again later"
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            size="large"
                            disabled={
                              !(
                                respondIn &&
                                questionsIn &&
                                flashcards.length > 0
                              )
                            }
                            onClick={() => {
                              setRoundStarted(true);
                              localStorage.setItem("round_started", true);
                            }}
                          >
                            start
                          </Button>
                        </span>
                      </Tooltip>
                    </CardContent>
                  </MUICard>
                </Grid>
              </Grid>
            </Container>
          )}
          {!roundStarted && bookmarks.length > 0 && (
            <Favorites
              bookmarks={bookmarks}
              handleClickBookmark={handleClickBookmark}
              cardIsStoredAsBookmark={cardIsStoredAsBookmark}
            />
          )}
          {roundStarted && determineCurrentCard() && (
            <Container>
              <MUICard align="center" variant="">
                <Button
                  onClick={() => {
                    setCurrentCardIndex(0);
                    localStorage.setItem("current_card_index", 0);
                    setResults(EMPTY_RESULTS);
                    localStorage.setItem("round_started", false);
                    setRoundStarted(false);
                    if (!flashcards) {
                      let cards = prepareDeck(flashcardQuantity);
                      setFlashcards(cards);
                    }
                  }}
                  variant="text"
                  size="small"
                  style={{ marginTop: "20px", padding: "10px" }}
                >
                  restart
                </Button>
                <Typography variant="subtitle2">
                  {currentCardIndex + 1} / {flashcards.length}
                </Typography>
              </MUICard>
              <Flashcard
                questionsIn={questionsIn}
                respondIn={respondIn}
                currentCard={determineCurrentCard()}
                currentCardIndex={currentCardIndex}
                setCurrentCardIndex={setCurrentCardIndex}
                userResponse={userResponse}
                setUserResponse={setUserResponse}
                results={results}
                setResults={setResults}
                showCharacter={showCharacter}
                setShowCharacter={setShowCharacter}
                showAudioTooltip={showAudioTooltip}
                setShowAudioTooltip={setShowAudioTooltip}
                bookmarks={bookmarks}
                setBookmarks={setBookmarks}
                handleClickBookmark={handleClickBookmark}
                cardIsStoredAsBookmark={cardIsStoredAsBookmark}
                getIndexOfExistingBookmark={getIndexOfExistingBookmark}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Container>
          )}
          {roundStarted && !determineCurrentCard() && (
            <Container>
              <MUICard variant="">
                <CardContent>
                  <CardHeader
                    className="card-header"
                    title="results:"
                    subheader={`${flashcards.length - results.correct} / ${
                      flashcards.length
                    } incorrect`}
                    titleTypographyProps={{ align: "center", variant: "h3" }}
                    subheaderTypographyProps={{ align: "center" }}
                  />
                </CardContent>
              </MUICard>
              <MUICard variant="" align="center">
                <Button
                  onClick={() => {
                    localStorage.setItem("current_card_index", 0);
                    setCurrentCardIndex(0);
                    setFlashcards(prepareDeck(flashcardQuantity));
                    setQuestionsIn(questionsIn);
                    setResults(EMPTY_RESULTS);
                    localStorage.setItem("round_started", false);
                    setRoundStarted(false);
                  }}
                  variant="outlined"
                >
                  home
                </Button>{" "}
                {results.incorrectCards.length >= 1 && (
                  <Button
                    onClick={() => {
                      const cards = results.incorrectCards;
                      localStorage.setItem("flashcards", JSON.stringify(cards));
                      setFlashcards(cards);
                      localStorage.setItem("current_card_index", 0);
                      setCurrentCardIndex(0);
                      setResults(EMPTY_RESULTS);
                      localStorage.setItem("round_started", true);
                      setRoundStarted(true);
                    }}
                    variant="contained"
                  >
                    retry wrong cards
                  </Button>
                )}
              </MUICard>
            </Container>
          )}
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
